export default (type='POST', url='', data) => {
    return new Promise((resolve, reject) => { //定义一个promise
        let settings = {
            type: "post",
            url: url,
            data: data,
            dataType: "json",
            cache: false,  
            processData: false,
            contentType: false 
        };
        let success = function(res) {
            if(res.code == 1){
                
            }else{
                util.msg(res.msg)
            }
            resolve(res)
        };
        let error = function(msg) {
            reject(msg);
        };
        settings.success = success;
        settings.error = error;
        $.ajax(settings);
    })
}