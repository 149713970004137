import Vue from 'vue'
import Router from 'vue-router'


const SignIn = r => require.ensure([], () => r(require('./views/signIn.vue')), 'signIn')
const SignUp = r => require.ensure([], () => r(require('./views/signUp.vue')), 'signUp')
const Home = r => require.ensure([], () => r(require('./views/home.vue')), 'home')
const Order = r => require.ensure([], () => r(require('./views/order.vue')), 'order')
const Mine = r => require.ensure([], () => r(require('./views/mine.vue')), 'mine')
const Msite = r => require.ensure([], () => r(require('./views/msite.vue')), 'msite')
const Setting = r => require.ensure([], () => r(require('./views/setting.vue')), 'setting')
const MineInfo = r => require.ensure([], () => r(require('./views/mineInfo.vue')), 'mineInfo')
const MineInfoName = r => require.ensure([], () => r(require('./views/mineInfoName.vue')), 'mineInfoName')
const MineInfoPhone = r => require.ensure([], () => r(require('./views/mineInfoPhone.vue')), 'mineInfoPhone')
const Address = r => require.ensure([], () => r(require('./views/address.vue')), 'address')
const AddressDetail = r => require.ensure([], () => r(require('./views/addressDetail.vue')), 'addressDetail')
const Location = r => require.ensure([], () => r(require('./views/location.vue')), 'location')
const Shop = r => require.ensure([], () => r(require('./views/shop.vue')), 'shop')
const ShopDetail = r => require.ensure([], () => r(require('./views/shopDetail.vue')), 'shopDetail')
const ShopComment = r => require.ensure([], () => r(require('./views/shopComment.vue')), 'shopComment')
const OrderConfirm = r => require.ensure([], () => r(require('./views/orderConfirm.vue')), 'orderConfirm')
const OrderDetail = r => require.ensure([], () => r(require('./views/orderDetail.vue')), 'orderDetail')
const Pay = r => require.ensure([], () => r(require('./views/pay.vue')), 'pay')
const OrderCancel = r => require.ensure([], () => r(require('./views/orderCancel.vue')), 'orderCancel')
const OrderEval = r => require.ensure([], () => r(require('./views/orderEval.vue')), 'orderEval')
const About = r => require.ensure([], () => r(require('./views/about.vue')), 'about')
const Contact = r => require.ensure([], () => r(require('./views/contact.vue')), 'contact')
const Search = r => require.ensure([], () => r(require('./views/search.vue')), 'search')
const SearchResult = r => require.ensure([], () => r(require('./views/searchResult.vue')), 'searchResult')
const Category = r => require.ensure([], () => r(require('./views/category.vue')), 'category')
const PasswordModify = r => require.ensure([], () => r(require('./views/passwordModify.vue')), 'passwordModify')
const PasswordReset = r => require.ensure([], () => r(require('./views/passwordReset.vue')), 'passwordReset')
const OrderRemark = r => require.ensure([], () => r(require('./views/orderRemark.vue')), 'orderRemark')
const PhoneBind = r => require.ensure([], () => r(require('./views/phoneBind.vue')), 'phoneBind')
const Config = r => require.ensure([], () => r(require('./views/config.vue')), 'config')
const NotFind = r => require.ensure([], () => r(require('./views/404.vue')), '404')
const Coupon = r => require.ensure([], () => r(require('./views/coupon.vue')), 'coupon')
const MineCoupon = r => require.ensure([], () => r(require('./views/mineCoupon.vue')), 'mineCoupon')
const UseCoupon = r => require.ensure([], () => r(require('./views/useCoupon.vue')), 'useCoupon')
const ShopMine = r => require.ensure([], () => r(require('./views/shopMine.vue')), 'shopMine')
const ShopOrder = r => require.ensure([], () => r(require('./views/shopOrder.vue')), 'shopOrder')
const MenuInfo = r => require.ensure([], () => r(require('./views/menuInfo.vue')), 'menuInfo')
const MemberCenter = r => require.ensure([], () => r(require('./views/memberCenter.vue')), 'MemberCenter')
const MemberPay = r => require.ensure([], () => r(require('./views/memberPay.vue')), 'MemberPay')
const Logist = r => require.ensure([], () => r(require('./views/logist.vue')), 'Logist')
const xxx = r => require.ensure([], () => r(require('./views/xxx.vue')), 'xxx')
Vue.use(Router)

export default new Router({
    history: false,
    base: __dirname,
    routes: [
        {
            path: '/123456',
            component: xxx,
        },
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/signIn',
            component: SignIn,
            meta: { share: true, keepAlive: false, title: '登录' }
        },
        {
            path: '/signUp',
            component: SignUp,
            meta: { share: true, keepAlive: false, title: '注册' }
        },
        {
            path: '/home',
            component: Home,
            meta: { share: true, keepAlive: false, title: '首页' }
        },
        {
            path: '/order',
            component: Order,
            meta: { share: true, keepAlive: false, title: '订单' }
        },
        {
            path: '/orderDetail',
            component: OrderDetail,
            meta: { share: true, keepAlive: false, title: '订单详情' }
        },
        {
            path: '/mine',
            component: Mine,
            meta: { share: true, keepAlive: false, title: '我的' }
        },
        {
            path: '/setting',
            component: Setting,
            meta: { share: true, keepAlive: false, title: '设置' }
        },
        {
            path: '/mineInfo',
            component: MineInfo,
            meta: { share: true, keepAlive: false, title: '个人信息' }
        },
        {
            path: '/mineInfoName',
            component: MineInfoName,
            meta: { share: true, keepAlive: false, title: '用户名修改' }
        },
        {
            path: '/mineInfoPhone',
            component: MineInfoPhone,
            meta: { share: true, keepAlive: false, title: '用户手机' }
        },
        {
            path: '/msite',
            component: Msite,
            meta: { share: true, keepAlive: false, title: '地址设置' }
        },
        {
            path: '/address',
            component: Address,
            meta: { share: true, keepAlive: false, title: '地址列表' }
        },
        {
            path: '/addressDetail',
            component: AddressDetail,
            meta: { share: true, keepAlive: true, title: '地址详情' }
        },
        {
            path: '/location',
            component: Location,
            meta: { share: true, keepAlive: false, title: '定位' }
        },
        {
            path: '/shop',
            component: Shop,
            meta: { share: false, keepAlive: false, title: '店铺页面' }
        },
        {
            path: '/shopDetail',
            component: ShopDetail,
            meta: { share: true, keepAlive: false, title: '店铺详情' }
        },
        {
            path: '/shopComment',
            component: ShopComment,
            meta: { share: true, keepAlive: false, title: '店铺评论' }
        },
        {
            path: '/orderConfirm',
            component: OrderConfirm,
            meta: { share: true, keepAlive: false, title: '订单确认' }
        },
        {
            path: '/pay',
            component: Pay,
            meta: { share: true, keepAlive: false, title: '支付' }
        },
        {
            path: '/orderCancel',
            component: OrderCancel,
            meta: { share: true, keepAlive: false, title: '订单取消' }
        },
        {
            path: '/orderEval',
            component: OrderEval,
            meta: { share: true, keepAlive: false, title: '订单评价' }
        },
        {
            path: '/about',
            component: About,
            meta: { share: true, keepAlive: false, title: '关于我们' }
        },
        {
            path: '/contact',
            component: Contact,
            meta: { share: true, keepAlive: false, title: '联系我们' }
        },
        {
            path: '/search',
            component: Search,
            meta: { share: true, keepAlive: false, title: '店铺搜索' }
        },
        {
            path: '/searchResult',
            component: SearchResult,
            meta: { share: true, keepAlive: false, title: '店铺搜索' }
        },
        {
            path: '/category',
            component: Category,
            meta: { share: true, keepAlive: false, title: '店铺分类' }
        },
        {
            path: '/passwordModify',
            component: PasswordModify,
            meta: { share: true, keepAlive: false, title: '密码修改' }
        },
        {
            path: '/passwordReset',
            component: PasswordReset,
            meta: { share: true, keepAlive: false, title: '密码重置' }
        },
        {
            path: '/orderRemark',
            component: OrderRemark,
            meta: { share: true, keepAlive: false, title: '订单备注' }
        },
        {
            path: '/config',
            component: Config,
            meta: { share: true, keepAlive: false, title: 'vConsole' }
        },
        {
            path: '/phoneBind',
            component: PhoneBind,
            meta: { share: true, keepAlive: false, title: '手机绑定' }
        },
        {
            path: '/coupon',
            component: Coupon,
            meta: { share: true, keepAlive: false, title: '领劵' }
        },
        {
            path: '/mineCoupon',
            component: MineCoupon,
            meta: { share: true, keepAlive: false, title: '我的优惠券' }    
        },
        {
            path: '/useCoupon',
            component: UseCoupon,
            meta: { share: true, keepAlive: false, title: '使用优惠券' }
        },{
            path: '/shopMine',
            component: ShopMine,
            meta: { share: true, keepAlive: false, title: '我的' }
        }, {
            path: '/shopOrder',
            component: ShopOrder,
            meta: { share: true, keepAlive: false, title: '我的' }
        }, {
            path: '/menuInfo',
            component: MenuInfo,
            meta: { share: true, keepAlive: false, title: '会员注册' }
        }, {
            path: '/memberCenter',
            component: MemberCenter,
            meta: { share: true, keepAlive: false, title: '会员中心' }
        }, {
            path: '/memberPay',
            component: MemberPay,
            meta: { share: true, keepAlive: false, title: '会员支付' }
        }, {
            path: '/logist',
            component: Logist,
            meta: { share: true, keepAlive: false, title: '物流详情' }
        },
        {
            path: '*',
            component: NotFind
        }
    ]
})


