import store from '../store.js'
const sUrl = store.state.apiUrl

export default async (type='GET', url='', data, indicator=true,isMsg=true) => {
    return new Promise((resolve, reject) => { //定义一个promise
        if(indicator === true){
            $.showIndicator()
        }
        let settings = {
            type: type,
            url: url,
            data: data,
            dataType:'json'
        };
        let success = function(res) {
            if(indicator === true){
                $.hideIndicator()
            }
            if(res.code == 1 || res.code == -1 || res.code == 16 || res.code == 8 || res.code == 17){
                resolve(res)
            }else if(res.code == 2){
                if((sUrl + "edcapi/Member_userInfo") != url){
                    store.state.user.userId = ""
                    store.state.user.sessionId = ""
                    Cookies.set('userId', store.state.user.userId, { expires: 60 })
                    Cookies.set('sessionId', store.state.user.sessionId, { expires: 60 })
                    store.commit("saveUser", store.state.user)
                    util.msg(res.msg)
                }
                resolve(res)
            }else{
                if (isMsg){
                    util.msg(res.msg)

                }
                resolve(res)
            }
        };
        let error = function(msg) {
            console.log(url + "网络状况不好")
            if(indicator === true){
                $.hideIndicator()
            }
            reject(msg);
        };
        settings.success = success;
        settings.error = error;
        $.ajax(settings);
    })
}