import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './config/api'

Vue.config.productionTip = false;

function isWeiXin() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;  //为微信
    } else {
        return false;  //不为微信
    }
}

if (isWeiXin()) {
    store.commit("saveIsBrowser", 1);
} else if (/AlipayClient/.test(window.navigator.userAgent)){
    store.commit("saveIsBrowser", 2);
}else{
    store.commit("saveIsBrowser", 3);
}

router.beforeEach((to, from, next) => {//页面加载时先获取用户信息
    if (to.meta.share === true) {
        api.wxSDK().then(res => {
            wx.config({
                debug: false,
                appId: res.data.appId,
                timestamp: res.data.timestamp,
                nonceStr: res.data.noncestr,
                signature: res.data.sign,
                jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'scanQRCode']
            });
            wx.ready(function () {
                let url = $.base64.encode("https://ydc.yichengshi.cn/")
                let shareData = {
                    title: "码点餐",
                    desc: "码点餐分享",
                    link: "https://ydc.yichengshi.cn/share.html?url=" + url,
                    imgUrl: "https://ydc.yichengshi.cn/@/assets/images/icon/logo.png"
                };
                wx.onMenuShareAppMessage(shareData);
                wx.onMenuShareTimeline(shareData);
            });
        })
    }
    if (to.meta.title) {
        document.title = to.meta.title
    }
    
    let value = {
        userId: Cookies.get('userId') || "",
        sessionId: Cookies.get('sessionId') || ""
    }
    if (!store.state.user.userId) {
        if (isWeiXin() && to.path == "/signIn" && to.fullPath != "/signIn?src=union") {
            // const url = encodeURIComponent("http://192.168.1.196:8080/#/signIn?src=union")

            const url = encodeURIComponent("https://ydc.yichengshi.cn/#/signIn?src=union")
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1b2aa42ec42711ab&redirect_uri=https://ydc.yichengshi.cn/edcapi/Login_loginUnionUser&response_type=code&scope=snsapi_userinfo&state=wx-" + url + "#wechat_redirect";
        }
        api.getUserInfo(value).then((res) => {
            console.log(res)
            let user = store.state.user
            if (res.code == 1) {
                user.userId = res.data.id
                user.sessionId = Cookies.get('sessionId') || ""
                Object.assign(user, res.data)

                api.getMqttId({
                    userId: store.state.user.userId,
                    sessionId: store.state.user.sessionId,
                    channelId: store.state.user.userId + store.state.user.sessionId
                }).then(res => {
                    setMqtt()
                })

                api.getMemberInfo({
                    shopId: Cookies.get("shopId") || "",
                    userId: Cookies.get("userId") || "",
                    sessionId: Cookies.get("sessionId") || ""
                }).then(resm => {
                    console.log(resm,'新修改')
                    if (resm.code == 1) {
                        if (resm.data.isEnable && resm.data.isExpire != 1) {
                            store.commit("saveMemberInfo", resm.data);

                        }else{
                            store.commit("saveIsMemberEnd",true);

                            console.log(store.state.isMemberEnd)

                        }
                        
                    }
                });
            } else if (res.code == 2) {
                user.userId = ""
                user.sessionId = ""
                Cookies.set('userId', user.userId, { expires: 60 })
                Cookies.set('sessionId', user.sessionId, { expires: 60 })
            }
            if (JSON.parse(sessionStorage.getItem("curLocation"))) {
                user.curLocation = JSON.parse(sessionStorage.getItem("curLocation"))
            }
            if (JSON.parse(sessionStorage.getItem("curAddress"))) {
                user.curAddress = JSON.parse(sessionStorage.getItem("curAddress"))
            }
            store.commit("saveUser", user)

            next()
        }, (res) => {
            next()
        })
    } else {
        setMqtt()
        next()
    }
})

const setMqtt = () => {
    if (store.state.user.mqtt == 1) {
        return false
    }
    const config = {
        host: 'post-cn-4590s0h3b04.mqtt.aliyuncs.com',// 设置当前用户的接入点域名，接入点获取方法请参考接入准备章节文档，先在控制台创建实例
        port: 443,//WebSocket 协议服务端口，如果是走 HTTPS，设置443端口
        topic: 'ydcxd',//需要操作的 Topic
        useTLS: true,//是否走加密 HTTPS，如果走 HTTPS，设置为 true
        accessKey: 'LTAIIe8dsko2dFc1',//账号的 AccessKey，在阿里云控制台查看
        secretKey: 'qGGK1on9p6eRiatCWhAImvfqMdZkVk',//账号的的 SecretKey，在阿里云控制台查看
        cleansession: true,
        groupId: 'GID_ydcxd',
        clientId: 'GID_ydcxd' + '@@@' + store.state.user.userId + store.state.user.sessionId//GroupId@@@DeviceId，由控制台创建的 Group ID 和自己指定的 Device ID 组合构成
    }
    let mqtt, message, reconnectTimeout = 2000, username = config.accessKey, password = CryptoJS.HmacSHA1(config.groupId, config.secretKey).toString(CryptoJS.enc.Base64);
    function MQTTconnect() {
        mqtt = new Paho.MQTT.Client(
            config.host,//MQTT 域名
            config.port,//WebSocket 端口，如果使用 HTTPS 加密则配置为443,否则配置80
            config.clientId//客户端 ClientId
        );
        let options = {
            timeout: 3,
            onSuccess: onConnect,
            mqttVersion: 4,
            onFailure: function (message) {
                setTimeout(MQTTconnect, reconnectTimeout);
            }
        };

        mqtt.onConnectionLost = onConnectionLost;
        mqtt.onMessageArrived = onMessageArrived;
        if (username != null) {
            options.userName = username;
            options.password = password;
            options.useSSL = config.useTLS;//如果使用 HTTPS 加密则配置为 true
        }
        mqtt.connect(options);
    }
    function onConnect() {
        mqtt.subscribe(config.topic, { qos: 0 });
        store.state.user.mqtt = 1
        store.commit("setData", { name: "user", data: store.state.user })
        // message = new Paho.MQTT.Message("Hello mqtt!!");//set body
        // message.destinationName = config.topic;// set topic
        // mqtt.send(message);
    }
    function onConnectionLost(response) {
        console.log(response)
        setTimeout(MQTTconnect, reconnectTimeout);
    };
    function onMessageArrived(message) {
        var topic = message.destinationName;
        var payload = message.payloadString;
        var value = (JSON.parse('' + payload + ''))["data"]["msgContent"];
        if (value.title == "购物车通知") {
            store.state.user.cartCanRefresh = true
            store.commit("setData", { name: "user", data: store.state.user })
        }
        notice.add(value.description)
        console.log("recv msg : " + topic + "   " + payload);
    };
    MQTTconnect();
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
