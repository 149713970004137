import fench from './ajax'
import fenchUpload from './upload'
import store from '../store.js'

const url = store.state.apiUrl, upload = store.state.uploadUrl
let api = {};

api.wxSDK = (value) => fench('GET', url + 'edcapi/Index_getWxJsTicket', value, false);//微信JS-SDK
api.uploadImage = (value) => fenchUpload('POST', 'https://api.yichengshi.cn/YmUpload_image', value);//图片上传
api.getImageCaptcha = (value) => fench('GET', url + 'edcapi/ImageCaptca_findImageCaptcha', value);//获取图片验证码
api.getRegistCaptcha = (value) => fench('GET', url + 'edcapi/Login_registCaptcha', value);//获取注册验证码
api.regist = (value) => fench('GET', url + 'edcapi/Login_regist', value);//注册
api.login = (value) => fench('GET', url + 'edcapi/Login_login', value);//登录
api.loginFast = (value) => fench('GET', url + 'edcapi/Union_loginByUnion', value);//快捷登录
api.getLoginFastCaptcha = (value) => fench('GET', url + 'edcapi/Union_banderCaptcha', value);//获取绑定手机号验证码
api.loginFastRegist = (value) => fench('GET', url + 'edcapi/Union_registByUnion', value);//快捷登录注册
api.getMqttId = (value) => fench('GET', url + 'edcapi/Login_modifyChannel', value, false);//读取Mqtt
api.loginOut = (value) => fench('GET', url + 'edcapi/Login_loginOut', value);//退出登录
api.passwordModify = (value) => fench('GET', url + 'edcapi/Member_modifyPass', value);//修改密码
api.passwordReset = (value) => fench('GET', url + 'edcapi/Login_findPassWord', value);//重置密码
api.getPasswordCaptcha = (value) => fench('GET', url + 'edcapi/Login_findPassCaptcha', value);//获取重置密码验证码
api.getIndexBanners = () => fench('GET', url + 'edcapi/Index_backgroundImg', {}, false);//获取首页广告
api.bindPhone = (value) => fench('GET', url + 'edcapi/Member_bindPhone', value);//绑定手机号
api.getIndexCategorys = () => fench('GET', url + 'edcapi/Index_indexShopCategoryList', {}, false);//读取首页分类
api.getShops = (value) => fench('GET', url + 'edcapi/Shop_shopList', value);//读取店铺列表
api.getUserInfo = (value) => fench('GET', url + 'edcapi/Member_userInfo', value, false);//读取用户信息
api.setUserInfo = (value) => fench('GET', url + 'edcapi/Member_modifyMyInfo', value);//设置用户信息
api.getAddresss = (value) => fench('GET', url + 'edcapi/UserAddress_myAddressList', value);//获取我的收货地址列表
api.getAddress = (value) => fench('GET', url + 'edcapi/xxx', value);//获取收货地址（没有此接口）
api.setAddress = (value) => fench('GET', url + 'edcapi/UserAddress_updateAddress', value);//更新收货地址
api.addAddress = (value) => fench('GET', url + 'edcapi/UserAddress_addAddress', value);//添加收货地址
api.deleteAddress = (value) => fench('GET', url + 'edcapi/UserAddress_delAddress', value);//删除收货地址
api.getShop = (value) => fench('GET', url + 'edcapi/Shop_shopDeatil', value);//读取店铺详情
api.getShopCategorys = (value) => fench('GET', url + 'edcapi/Shop_goodsCategoryList', value);//读取店铺详情
api.getShopGoods = (value) => fench('GET', url + 'edcapi/Goods_goodsList', value);//读取店铺商品
api.getCart = (value) => fench('GET', url + 'edcapi/Cart_cartDetail', value);//读取店铺购物车
api.setCart = (value) => fench('GET', url + 'edcapi/Cart_updateToCart', value);//添加购物车的商品
api.clearCart = (value) => fench('GET', url + 'edcapi/Cart_delToCart', value);//清空购物车的商品
api.getComments = (value) => fench('GET', url + 'edcapi/Shop_commentsList', value);//获取店铺评论列表
api.getCommentNum = (value) => fench('GET', url + 'edcapi/Shop_commentsCount', value);//获取店铺评论总数
api.subOrder = (value) => fench('POST', url + 'edcapi/Order_subOrder', value);//下单
api.getOrders = (value) => fench('GET', url + 'edcapi/Order_myOrders', value);//获取订单列表
api.getOrder = (value) => fench('GET', url + 'edcapi/Order_myOrdersDetail', value);//获取订单详情
api.cancelOrder = (value) => fench('GET', url + 'edcapi/Order_cancelOrder', value);//取消订单
api.backOrder = (value) => fench('GET', url + 'edcapi/Order_applyBack', value);//申请退款
api.continueOrder = (value) => fench('GET', url + 'edcapi/Cart_updateToContinue', value);//继续点餐
api.subOrderAgain = (value) => fench('GET', url + 'edcapi/Order_buyAgain', value);//再来一单
api.payOrder = (value) => fench('GET', url + 'edcapi/Pay_userPayApi', value);//支付订单
api.confirmOrder = (value) => fench('GET', url + 'edcapi/Order_confirmOrder', value);//确认收货
api.evalOrder = (value) => fench('GET', url + 'edcapi/Order_addComment', value);//订单评论
api.myOrders = (value) => fench('GET', url + 'edcapi/Order_myOrders', value);//获取未完成订单
api.cashCouponBatchList = (value) => fench('GET', url + 'edcapi/Order_cashCouponBatchList', value);//优惠券领取列表
api.receiveCashCoupon = (value) => fench('GET', url + 'edcapi/Order_receiveCashCoupon', value);//优惠券领取列表
api.cashCouponList = (value) => fench('GET', url + 'edcapi/Order_cashCouponList', value, true, false);//优惠券领取列表
api.getMemberCard = (value) => fench('GET', url + 'edcapi/Member_getDefaultMemberCard', value); //默认会员卡
api.createShopMember = (value) => fench('POST', url + 'edcapi/Member_createShopMember', value); //用户创建会员
api.getMemberInfo = (value) => fench('GET', url + 'edcapi/Member_getMemberInfo', value); //获取会员信息
api.operateOrder = (value) => fench('POST', url + 'edcapi/Order_operateOrder', value); //会员卡绑定
api.memberActivity = (value) => fench('POST', url + 'edcapi/ShopMember_shopMemberActivityList', value); //会员活动
api.payByShopMemberCard = (value) => fench('POST', url + 'edcapi/Order_payByShopMemberCard', value); //会员支付
api.getMemberRecharge = (value) => fench('GET', url + 'edcapi/Member_shopMemberRecharge', value); //用户充值
api.getShopMemberMoneyLog = (value) => fench('POST', url + 'edcapi/ShopMember_getShopMemberMoneyLog', value); //会员消费记录
api.getEleOrderShipDetail = (value) => fench('POST', url + 'edcmanageapi/Ship_getEleOrderShipDetail', value); //蜂鸟
api.findImageCaptcha = (value) => fench('POST', url + 'edcapi/ImageCaptca_findImageCaptcha', value); //图形验证码
export default api;