import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiUrl: localStorage.apiUrl || "https://ydc.yichengshi.cn/",
        // apiUrl: "http://192.168.110.201:8084/",
        user: {
            userType: "",
            userId: "",
            headLogo: "",
            isDelete: "",
            isEnable: "",
            nickName: '',//姓名
            userName: '',//手机号码
            registTime: "",
            sessionId: '',//
            cartCanRefresh: false,
            mqtt: 0,
            curLocation: {
                address: "",
                doorNum: "",
                latitude: "",
                longitude: "",
                name: "",
                phone: "",
                sex: "",
                userAddressId: ""
            },
            curAddress: {
                address: "",
                doorNum: "",
                latitude: "",
                longitude: "",
                name: "",
                phone: "",
                sex: "",
                userAddressId: ""
            },
            isFirst: true
        },
        synthesisOrderTypes: [
            {
                value: 0,
                name: "综合排序"
            }, {
                value: 1,
                name: "速度最快"
            }, {
                value: 2,
                name: "评分最高"
            }, {
                value: 3,
                name: "起送价最低"
            }, {
                value: 4,
                name: "配送费最低"
            }, {
                value: 5,
                name: "人均从高到低"
            }, {
                value: 6,
                name: "人均从低到高"
            }
        ],
        categoryList: [],
        bannerList: [],
        shopList: [],
        shopParam: {
            synthesisOrderType: 0,//综合排序
            nearbyOrderType: 0,//距离排序
            salesVolumeOrderType: 0,//销量排序 
            shopCategoryId: 0,//店铺分类Id
            edcfuncTags: "",
            pageNo: 1,
            isLoading: false,
            isNotData: false,
            isUpdateLocation: true
        },
        shopDetail: {},
        shopClassify: [],
        shopCart: {},
        remark: {
            items: [
                {
                    desc: "",
                    items: ["不要辣", "少点辣", "多点辣"]
                }, {
                    desc: "",
                    items: ["加个饭"]
                }, {
                    desc: "",
                    items: ["多点葱"]
                }, {
                    desc: "",
                    items: ["多点醋"]
                }, {
                    desc: "",
                    items: ["不要洋葱"]
                }, {
                    desc: "",
                    items: ["不要香菜"]
                }
            ],
            content: "",
            wordCount: 50,
            buyerMark: ""
        },
        orders: {
            list: [],
            pageNo: 1,
            isLoading: false,
            isNotData: false
        },
        memberInfo: '',
        isMemberEnd: false,
        isBrowser: 0,//1表示微信,2表示支付宝
        shopDetail: '' //商铺信息
    },
    mutations: {
        saveUser(state, data) {
            state.user = data;
        },
        saveMemberInfo(state, data) {
            state.memberInfo = data;

            console.log(state.memberInfo)
        },
        saveIsBrowser(state, data) {
            state.isBrowser = data;
        },
        saveIsMemberEnd(state, data) {
            state.isMemberEnd = data;
        },
        setData(state, obj) {
            state[obj.name] = obj.data
        },
        clearObjData(state, name) {
            Object.keys(state[name]).forEach(key => state[name][key] = '');
        },
        clearShop(state) {
            state.shopList = []
            state.shopParam = {
                synthesisOrderType: 0,//综合排序
                nearbyOrderType: 0,//距离排序
                salesVolumeOrderType: 0,//销量排序 
                shopCategoryId: 0,//店铺分类Id
                edcfuncTags: "",
                pageNo: 1,
                isNotData: false,
                isLoading: false,
                isUpdateLocation: true
            }
        },
        clearOrders(state) {
            state.orders = {
                list: [],
                pageNo: 1,
                isLoading: false,
                isNotData: false
            }
            state.isMemberEnd = false;
            state.memberInfo = '';
        }
    },
    actions: {

    },
    getters: {

    }
})
