<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
import api from './config/api'
export default {
    name: 'app',
    components: {},
    data() {
        return {
            user: this.$store.state.user
        }
    },
    created: function () {
        
    }, 
    mounted(){
        
    },
    methods: {
        
    }
}
</script>
